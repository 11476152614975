.dividedCosts {
	margin-top: 25px;
}

li {
	list-style-type: none;
	margin-top: 15px;
}

hr {
	border: 1px solid rgb(222, 222, 222);
	padding-right: revert;
}

.personOwes {
	font-size: 1.2em;
	font-weight: 500;
}

.moreDetail button {
	background-color: rgb(248, 144, 68);
	border-radius: 6px;
	color: white;
	display: inline-block;
	border: none;
	font-size: 1.6em;
	margin-top: 13px;
	margin-bottom: 8px;
	padding-top: 1px;
	padding-bottom: 3px;
	padding-left: 6px;
	padding-right: 6px;
	cursor: pointer;
	height: 1em;
}

@media (hover: hover) and (pointer: fine) {
	.moreDetail button:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
		background-color: rgb(220, 93, 0);
	}
}

.ate {
	margin-top: 10px;
	margin-bottom: 15px;
	border: 3px solid rgb(222, 222, 222);
	padding-top: 10px;
	padding-bottom: 6px;
	padding-left: 6px;
	padding-right: 6px;
	border-radius: 6px;
}

.detailHeader {
	font-weight: 500;
}