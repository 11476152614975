.editPerson {
	background-color: rgb(222, 222, 222);
	border-radius: 6px;
	width: fit-content;
	text-align: center;
	margin: auto;
	margin-top: 23px;
	margin-bottom: 10px;
	padding-left: 15px;
	padding-right: 15px;
	padding-top: 6px;
	padding-bottom: 60px;
}

.cancelChanges button {
  background-color: inherit;
  border-radius: 6px;
  color: rgb(86, 85, 85);
  display: inline-block;
  border: none;
  font-size: 1.3em;
  margin-top: 5px;
  margin-bottom: 15px;
  padding: 0px;
  text-align: center;
  cursor: pointer;
  float: left;
}

@media (hover: hover) and (pointer: fine) {
  .cancelChanges button:hover {
    color: rgb(24, 24, 24);
    height: 1em;
    width: 1em;
    border-radius: 50%;
    background-color: rgb(195, 195, 195);
    margin-bottom: 20px;
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    font-weight: bold;
  }
}

.removePerson button {
  background-color: rgb(250, 60, 60);
  border-radius: 6px;
  color: white;
  border: none;
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 8px;
  margin-left: 2px;
  padding-top: 9px;
  padding-bottom: 6px;
  padding-left: 14px;
  padding-right: 14px;
  cursor: pointer;
  float: left;
}

@media (hover: hover) and (pointer: fine) {
  .removePerson button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
    background-color: rgb(203, 0, 0);
  }
}

.saveChanges button {
  background-color: rgb(54, 161, 236);
  border-radius: 6px;
  color: white;
  border: none;
  font-size: 1.2em;
  margin-top: 10px;
  margin-bottom: 8px;
  margin-right: 5px;
  padding-top: 7px;
  padding-bottom: 4px;
  padding-left: 13px;
  padding-right: 13px;
  cursor: pointer;
  float: right;
}

@media (hover: hover) and (pointer: fine) {
  .saveChanges button:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  	background-color: rgb(7, 104, 173);
  }
}