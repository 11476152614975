@import url('https://fonts.googleapis.com/css2?family=Sono:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');

.App {
    text-align: center;
    font-family: 'Rubik', Arial, Helvetica, sans-serif;
    background-color: white;
}

input {
    background-color: white;
    border-radius: 3px;
    border: none;
    border: 2px solid rgb(222, 222, 222);
    border-radius: 4px;
    font-size: 0.9em;
    margin-bottom: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a {
    color: rgb(54, 161, 236);
    text-decoration: none;
}

@media (hover: hover) and (pointer: fine) {
    a:hover {
        color: rgb(54, 161, 236);
        text-decoration: underline;
    }
}

.singleButtonBack {
    width: 100px;
    margin: auto;
    display: flex;
    align-content: center;
    justify-content: left;
}

.singleButtonNext {
    width: 100px;
    margin: auto;
    display: flex;
    align-content: center;
    justify-content: right;
}

.doubleButton {
    width: 100px;
    margin: auto;
    display: flex;
    align-content: center;
    justify-content: center;
    gap: 10px;
}

.back button {
    background-color: rgb(185, 185, 185);
    border-radius: 6px;
    color: white;
    display: inline-block;
    border: none;
    font-size: 1.2em;
    margin-top: 10px;
    margin-bottom: 8px;
    padding-top: 7px;
    padding-bottom: 3px;
    padding-left: 12px;
    padding-right: 12px;
    vertical-align: top;
    cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
    .back button:hover {
        box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
        background-color: #858585;
    }
}

.next button {
    background-color: rgb(185, 185, 185);
    border-radius: 6px;
    color: white;
    display: inline-block;
    border: none;
    font-size: 1.2em;
    margin-top: 10px;
    margin-bottom: 8px;
    padding-top: 7px;
    padding-bottom: 3px;
    padding-left: 12px;
    padding-right: 12px;
    vertical-align: top;
    cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
    .next button:hover {
        box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
        background-color: #858585;
    }
}
