.calculateCosts {
	font-size: 1.3em;
	min-width: 100px;
	width: fit-content;
	margin: auto;
	margin-top: 23px;
	margin-bottom: 18px;
	text-align: right;
}

.cost {
	font-size: 1.2em;
	font-weight: 500;
	margin-top: 11px;
}

.displayTandT {
	margin-top: 7px;
}

.subtotal {
	font-size: 1.2em;
	font-weight: 800;
	margin-top: 17px;
}

.exportText button {
	background-color: rgb(176, 119, 249);
	border-radius: 6px;
	color: white;
	display: inline-block;
	border: none;
	font-size: 0.9em;
	margin-top: 12px;
	margin-bottom: 12px;
	padding-top: 7px;
	padding-bottom: 5px;
	padding-left: 13px;
	padding-right: 13px;
	cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
	.exportText button:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
		background-color: rgb(147, 69, 246);
	}
}

.showSplitEvenlyToggleButton button {
	background-color: inherit;
	color: rgb(86, 85, 85);
	display: inline-block;
	border: none;
	font-size: 1.2em;
	margin-top: 6px;
	margin-bottom: 6px;
	padding: 0px;
	text-align: center;
	cursor: pointer;
	outline: none;
}

@media (hover: hover) and (pointer: fine) {
	.showSplitEvenlyToggleButton button:hover {
		color: rgb(24, 24, 24);
		height: 1em;
		width: 1em;
		border-radius: 50%;
		background-color: rgb(195, 195, 195);
		margin-bottom: 12px;
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
	}
}