.notSelected button {
    background-color: white;
    cursor: pointer;
}

.selected button {
	background-color: green;
    cursor: pointer;
}

.save button {
    background-color: rgb(54, 161, 236);
    border-radius: 6px;
    color: white;
    border: none;
    font-size: 1.2em;
    margin-top: 10px;
    margin-bottom: 8px;
    padding-top: 7px;
    padding-bottom: 4px;
    padding-left: 12px;
    padding-right: 12px;
    cursor: pointer;
}

@media (hover: hover) and (pointer: fine) {
    .save button:hover {
        box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
        background-color: rgb(7, 104, 173);
    }
}

.addTaxTip {
    margin-top: 30px;
}

.addTaxTipInputField {
    font-size: 1.1em;
    margin-left: 10%;
    margin-right: 10%;
}

.toggleLable {
	display: inline-block;
	border-top: 8px;
	padding-right: 8px;
	text-align: center;
	font-size: 0.8em;
	margin-block-end: 0px;
	margin-block-start: 0px;
}

.textAndToggle {
	margin-top: 6px;
	margin-bottom: 8px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 18px;
    margin: auto;
    vertical-align: middle;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 4px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked + .slider {
    background-color: #4390cd;
}

input:focus + .slider {
    box-shadow: 0 0 1px #4390cd;
}

input:checked + .slider:before {
    -webkit-transform: translateX(15px);
    -ms-transform: translateX(15px);
    transform: translateX(15px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}

.alertContainer {
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    opacity: 1;
    -webkit-animation: fadeinout 1.5s linear forwards;
    animation: fadeinout 1.5s linear forwards;
}

.alertInner {
    display: inline-block;
    padding: 8px 16px;
    border-radius: 6px;
    color: white;
    z-index: 1;
    background-color: rgb(54, 161, 236);
    box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
    -webkit-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
    -moz-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 70%);
}

@keyframes fadeinout {
    0% { opacity: 0 }
    50% { opacity: 1 }
    100% { opacity: 0 }
}
