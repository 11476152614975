.addItem {
	text-align: left;
	margin-top: 40px;
	margin-left: auto;
	margin-right: auto;
	width: 15em;
}

.addItemTitle {
	font-size: 1.4em;
	margin-bottom: 12px;
	font-weight: bold;
}

.addItemInputField {
	font-size: 1.1em;
}

.addItemInputField label{
	display: inline-block;
	text-align: left;
}

.addItemInputField input{
	display: inline-block;
	text-align: left;
	margin-left: 1%;
	width: 9em;
}

.addItemButton button {
	background-color: rgb(77, 191, 98);
	border-radius: 6px;
	color: white;
	display: inline-block;
	border: none;
	font-size: 1.2em;
	margin-top: 10px;
	margin-bottom: 8px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 7px;
	padding-bottom: 4px;
	padding-left: 12px;
	padding-right: 12px;
	cursor: pointer;
	margin-top: 1em;
  	position: relative;
  	left: 50%;
  	-ms-transform: translateX(-50%);
  	transform: translateX(-50%);
}

@media (hover: hover) and (pointer: fine) {
	.addItemButton button:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
		background-color: rgb(26, 126, 44);
	}
}