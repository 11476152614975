.indvPerson {
	border: 3px solid rgb(222, 222, 222);
	border-radius: 6px;
	width: fit-content;
	text-align: center;
	margin: auto;
	margin-top: 30px;
	margin-bottom: 9px;
	font-size: 1.3em;
	min-width: 100px;
	padding-bottom: 5px;
/*	animation: fade 1.5s linear;*/
}

@keyframes fade {
	0% { opacity: 0 }
	100% { opacity: 1 }
}

.name {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	padding-left: 1.5em;
	padding-right: 1.5em;
	padding-top: 0.5em;
	padding-bottom: 0.2em;
	height: fit-content;
	font-weight: 600;
}
