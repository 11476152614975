.matchHelp {
	background: rgb(222, 222, 222);
	border: 3px solid rgb(222, 222, 222);
	border-radius: 6px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 10px;
    margin-right: 10px;
    padding: 0.2em;
    font-size: 1.1em;
    font-weight: bold;
    color: grey;
    display: inline-block;
}

@media (hover: hover) and (pointer: fine) {
	.matchHelp:hover {
		text-decoration: underline;
		cursor: pointer;
	}
}

.indvMatchPersonFood {
	border: 3px solid rgb(222, 222, 222);
	border-radius: 6px;
	width: fit-content;
	text-align: center;
	margin: auto;
	margin-top: 8px;
	margin-bottom: 10px;
	padding-bottom: 10px;
	padding-left: 30px;
	padding-right: 30px;
	font-size: 1.3em;
	min-width: 100px;
}

.matchName {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	padding-left: 1.5em;
	padding-right: 1.5em;
	padding-top: 0.2em;
	padding-bottom: 0.2em;
	height: fit-content;
	font-weight: 600;
	text-decoration: underline 2px;
	margin-top: 6px;
}

.matchPrice {
	padding-top: 0.5em;
	padding-bottom: 0.5em;
}

.matchDescription {
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	padding-left: 1.5em;
	padding-right: 1.5em;
	padding-top: 0.2em;
	padding-bottom: 0.2em;
	height: fit-content;
}

.personSelect {
	min-width: 200px;
}

.personName {
	display: inline-block;
	width: 40px;
	margin-right: 90px;
}

.notSelected button {
	width: 10px;
	border: 3px;
	border-radius: 3px;
	height: fit-content;
	width: fit-content;
	font-size: 0.9em;
	cursor: pointer;
	display: inline-block;
	background: rgb(222, 222, 222);
	margin-top: 12px;
	text-align: center;
	padding: 7px;
	color: black;
}

@media (hover: hover) and (pointer: fine) {
	.notSelected button:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
	}
}

.selected button {
	width: 10px;
	border: 3px;
	border-radius: 3px;
	height: fit-content;
	width: fit-content;
	font-size: 0.9em;
	cursor: pointer;
	display: inline-block;
	background: rgb(77, 191, 98);
	margin-top: 12px;
	text-align: center;
	padding: 7px;
	color: black;
}

@media (hover: hover) and (pointer: fine) {
	.selected button:hover {
		box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
	}
}

.emphasize {
	font-style: italic;
	font-weight: 600;
}
