.tip {
    display: inline-block;
    padding: 2px 6px;
    padding-bottom: 6px;
    border-radius: 6px;
    color: black;
    z-index: 1;
    background-color: #F8F8F8;
    box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 40%);
    -webkit-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 40%);
    -moz-box-shadow: 1px 2px 10px -3px rgb(0 0 0 / 40%);
    width: 14em;
    font-size: 0.9em;
    max-width: 75vw;
    animation: fade 2s linear;
}

.closeTip {
    margin-left: 10px;
    margin-right: 10px;
    padding: 0;
    padding-top: 8px;
    border: none;
    background: none;
    font-size: 0.9em;
    color: grey;
}

@media (hover: hover) and (pointer: fine) {
    .closeTip:hover {
    	text-decoration: underline;
        cursor: pointer;
    }
}